document.getElementById('edit-me').contentEditable='true';

let showInfo = document.querySelectorAll(".show-info");

for (var item of showInfo) {
  item.addEventListener('click', (e)=>{
    e.preventDefault();
    document.querySelector("body").classList.toggle("info-is-visible");
  })
}

var tday=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
var tmonth=["01","02","03","04","05","06","07","08","09","10","11","12"];

function GetClock(){
	var d=new Date();
	var nday=d.getDay(),nmonth=d.getMonth(),ndate=d.getDate(),nyear=d.getFullYear();
	var nhour=d.getHours(),nmin=d.getMinutes(),nsec=d.getSeconds(),ap;
	
	if(ndate<=9) ndate="0"+ndate;
	if(nhour<=9) nhour="0"+nhour;
	if(nmin<=9) nmin="0"+nmin;
	if(nsec<=9) nsec="0"+nsec;
	
	var clocktext=""+nyear+"-"+tmonth[nmonth]+"-"+ndate+"T"+nhour+":"+nmin+":"+nsec+"";
	
	document.getElementById('clock').innerHTML=clocktext;
}

GetClock();
setInterval(GetClock,1000);

// Get the video
var video = document.getElementById("video");

// Get the button
var btn = document.getElementById("video-button");

// Pause and play the video, and change the button text
function videoControl() {
  if (video.paused) {
    video.play();
    btn.innerHTML = "PAUSE";
  } else {
    video.pause();
    btn.innerHTML = "PLAY";
  }
}